<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :form_data_seo="form_data_seo"
			:slot_table_list="['operation']"
            ref="list"
            :rowKey="'id'"
        >
            <template slot="left_btn">
                <a-button type="primary" @click="$router.back()">返回客户列表</a-button>
            </template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="view_all(data.record)">查看全部</a>
			</template>
        </TableList>
		<a-modal v-model:visible="visible" title="查看全部" :footer="null">
			<div class="modal_top">
				<div class="item">
					<div class="left">操作类型：</div>
					<div class="right">{{details.type_name}}</div>
				</div>
				<div class="item">
					<div class="left">操作人：</div>
					<div class="right">{{details.operator}}</div>
				</div>
				<div class="item">
					<div class="left">操作时间：</div>
					<div class="right">{{details.opera_time}}</div>
				</div>
			</div>
			<div class="modal_top">
				<div class="item">
					<div class="left">日志内容：</div>
				</div>
			</div>
			<a-table :columns="modal_columns" :dataSource="details.content" :pagination="false">
				<template slot="field" slot-scope="key,item">
				    <div v-if="details.type == 'edit'">
						{{details.type_name + '：' + details.operator + '，将' + item.name + '由' + item.old_value + '修改为' + item.new_value}}
					</div>
					<div v-else>
						{{item.text}}
					</div>
				</template>
			</a-table>
		</a-modal>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import SelectUser from "@/components/SelectUser";

import { getLog,getLogContent } from "@/api/customer";

const columns = [
    {
		width:200,
        title: "操作类型",
        dataIndex: "type_name"
    },
    {
		width:200,
        title: "操作人",
        dataIndex: "operator"
    },
    {
		width:200,
        title: "操作时间",
        dataIndex: "opera_time"
    },
    {
        title: "日志内容",
        dataIndex: "content",
		ellipsis: true,
    },
	{
		width:200,
	    title: "操作",
	    dataIndex: "operation",
	    scopedSlots: {
	        customRender: "operation"
	    }
	}
];

const modal_columns = [
	{
		width:100,
		title: "日志内容",
		dataIndex: "field",
		key:'field',
		scopedSlots: {
		    customRender: "field"
		}
	}
];

let status_list = [
    {
        key: 1,
        value: '运营中'
    },
    {
        key: -1,
        value: '封存'
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        SelectUser
    },
    data() {
        return {
			visible:false,
            config: this.$config,
            get_table_list: getLog,
            columns,
			modal_columns,
            status_list,
            selectedRowKeys: [],
            loading: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "operator",
                        title: "操作人",
                        placeholder: "操作人姓名",
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "操作时间",
						start: {
						    name: 'start_time'
						},
						end: {
						    name: 'end_time'
						},
                    },
                ],
                ...this.$config.form_data_seo
            },
			details:{
				content:[
					
				],
			}
        };
    },

    computed: {
		
	},
    async created() {
    	
    },
    methods: {
		view_all(item){
			let loading = this.$message.loading();
			getLogContent({
				data:{
					id:item.id
				}
			}).then(res=>{
				let data = res.data.data;
				this.details.type = data.type;
				this.details.type_name = data.type_name;
				this.details.operator = data.operator;
				this.details.opera_time = data.opera_time;
				this.details.content = data.type == 'edit' ? data.change_data: [{text:data.content}];
				this.visible = true;
				loading();
			})
		},
	}
};
</script>

<style lang="less">
	.modal_top{
		display: flex;
		flex-wrap: wrap;
		.item{
			width: 50%;
			display: flex;
			align-items: flex-start;
			font-size: 14px;
			margin-bottom: 16px;
			.left{
				color: #333;
			}
			.right{
				color:#999,
			}
		}
	}
	.ant-table-thead{
		display: none;
	}
</style>